import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query/react";
import { ErrorApi } from "../types";
import { Error } from "./Error";

export function RequestError({ error }:{ error: FetchBaseQueryError | SerializedError }) {
    console.error(error);

    if ('status' in error) {
        if (typeof error.status === 'number') {
            const errorData = error.data as ErrorApi;
            const message = `Произошла ошибка: ${errorData.errorDescription}. Correlation ID: ${errorData.correlationId}`;
            return (<Error message={message} />);
        }

        const message = `Произошла ошибка ${error.status}: ${error.error}.`;
        return (<Error message={message} />);
    }

    const errorFields = Object.entries(error).map((value) => `${value[0]}: ${value[1]}`);
    const message = `Произошла ошибка ${errorFields.length > 0 ? errorFields.join(', ') : ''}`;
    return (<Error message={message} />)
}