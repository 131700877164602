import './commission.css';
import { BuySellCommission, Commission, CommissionsType, SwapCommission } from "./types";
import { BuySellForm } from "./CommissionForms/BuySellForm";
import { useCreateCommission, useDeleteCommission, useUpdateCommission } from './commissions.slice';
import LoadingButton from '@mui/lab/LoadingButton';
import { IconButton } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useEffect, useState } from 'react';
import { SwapForm } from './CommissionForms/SwapForm';

export function CommissionForm({
    clientCode, commissionType, commission, closeForm
}: {
    clientCode: string, commissionType: CommissionsType, commission?: Commission, closeForm?: () => unknown
}) {
    const [commissionValue, setCommissionValue] = useState(commission);

    useEffect(() => {
        setCommissionValue(commission);
    }, [commission]);

    function getCommissionFormFields(commissionType: CommissionsType) {
        switch (commissionType) {
            case CommissionsType.BuySell:
                return () => (<BuySellForm
                    commission={commission?.value as BuySellCommission}
                    updateCommission={(newCommission) => setCommissionValue({ value: newCommission })}
                />);
            case CommissionsType.Swap:
                return () => (<SwapForm
                    commission={commission?.value as SwapCommission}
                    updateCommission={(newCommission) => setCommissionValue({ value: newCommission })}
                />);
            default:
                return () => <p>Неизвестная комиссия</p>;
        }
    }

    return (
        <div className="commission-form">
            {
                getCommissionFormFields(commissionType)()
            }
            <div style={{ width: '150px', display: 'flex', justifyContent: 'end', gap: '50px', alignItems: 'start' }}>
                {
                    commission
                        ? (<ChangeCommissionButton
                            commissionType={commissionType}
                            code={clientCode}
                            commissionValue={commissionValue}
                        />)
                        : (<CreateCommissionButton
                            commissionType={commissionType}
                            code={clientCode}
                            commissionValue={commissionValue}
                        />)
                }
            </div>
            {
                commission
                    ? (<DeleteCommissionButton commissionType={commissionType} code={clientCode} />)
                    : null
            }
        </div>
    )
}

function checkCommission(commissionType: CommissionsType, commission?: Commission) {
    switch (commissionType) {
        case CommissionsType.BuySell:
            const buysell = commission?.value as BuySellCommission;
            return buysell?.rate > 0;
        case CommissionsType.Swap:
            const swap = commission?.value as SwapCommission;
            return swap?.reduce((prev, current) => {
                const isLowerBoundCorrect = Number.isInteger(current.lowerBound) && current.lowerBound > 0;
                const isUpperBoundCorrect = current.upperBound === null || (Number.isInteger(current.upperBound) && Number(current.upperBound) > current.lowerBound);
                const isRateCorrect = Number(current.rate) > 0;
                return prev && isLowerBoundCorrect && isUpperBoundCorrect && isRateCorrect;
            }, true);
        default: return false;
    }
}

function CreateCommissionButton({
    commissionType, code, commissionValue
}: {
    commissionType: CommissionsType, code: string, commissionValue?: Commission
}) {
    const [createCommission, createResult] = useCreateCommission();

    return (
        <>
            <LoadingButton
                variant="contained"
                type='submit'
                onClick={() => {
                    if (!checkCommission(commissionType, commissionValue)) {
                        return;
                    }
                    createCommission({
                        commissionType,
                        code,
                        value: commissionValue!.value,
                    });
                }}
                loading={createResult.isLoading}
            >
                Добавить
            </LoadingButton>
        </>
    )
}

function ChangeCommissionButton({
    commissionType, code, commissionValue
}: {
    commissionType: CommissionsType, code: string, commissionValue?: Commission
}) {
    const [updateCommission, updateResult] = useUpdateCommission();

    if (!commissionValue) {
        return (
            <LoadingButton
                disabled={true}
            >
                Изменить
            </LoadingButton>
        )
    }

    return (
        <>
            <LoadingButton
                variant="contained"
                type='submit'
                loading={updateResult.isLoading}
                onClick={() => {
                    if (!checkCommission(commissionType, commissionValue)) {
                        return;
                    }
                    updateCommission({
                        commissionType,
                        code,
                        value: commissionValue.value,
                    });
                }}
            >
                Изменить
            </LoadingButton>
        </>
    )
}

function DeleteCommissionButton({
    commissionType, code, closeForm
}: {
    commissionType: CommissionsType, code: string, closeForm?: () => void
}) {
    const [deleteCommission, deleteResult] = useDeleteCommission();

    return (
        <IconButton
            onClick={() => {
                closeForm?.();
                deleteCommission({ code, commissionType })
            }}
            sx={{ marginLeft: '20px' }}
        >
            <DeleteOutlineIcon />
        </IconButton>
    )
}