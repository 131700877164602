import { TableCell, TableRow } from "@mui/material";

import { getDateFormat, getTimeFormat } from "../../../shared/helpers/timeFormat";
import { EditDealButton } from "./EditDealButton";
import { Deal } from "../deal.dto";
import { DealFilterParams } from "./DealList/DealFilter";


export const DealCard = ({ deal, filterParams }: { deal: Deal, filterParams: DealFilterParams }) => {
    const baseCurrency = deal.instrument.split('/')[0]
    const quotedCurrency = (deal.instrument.split('/')[1]).split('_')[0];

    return (
        <TableRow>
            <TableCell>{getDateFormat(new Date(deal.dealDateTime))}</TableCell>
            <TableCell>{getTimeFormat(new Date(deal.dealDateTime))}</TableCell>

            <TableCell>{deal.instrument}</TableCell>

            <TableCell>{
                filterParams.ClientCode && filterParams.ClientCode === deal.buyerCode
                    ? <b>{deal.buyerCode}</b>
                    : deal.buyerCode
            }</TableCell>
            <TableCell>{
                filterParams.ClientCode && filterParams.ClientCode === deal.sellerCode
                    ? <b>{deal.sellerCode}</b>
                    : deal.sellerCode
            }</TableCell>

            <TableCell>{Number(deal.value).toLocaleString('ru')}</TableCell>

            <TableCell>{Number(deal.rate).toLocaleString('ru', { minimumFractionDigits: 4 })}</TableCell>
            <TableCell>
                {
                    deal.valueDateTimeBase === deal.valueDateTimeQuoted
                        ? getDateFormat(new Date(deal.valueDateTimeBase))
                        : `${baseCurrency} ${getDateFormat(new Date(deal.valueDateTimeBase))} 
                            ${quotedCurrency} ${getDateFormat(new Date(deal.valueDateTimeQuoted))}`
                }
            </TableCell>
            <TableCell>{deal.counterparty}</TableCell>
            <TableCell>{Number(deal.cbrfRate).toLocaleString('ru', { minimumFractionDigits: 4 })}</TableCell>
            <TableCell>
                {
                    deal.firstRate
                        ? Number(deal.firstRate).toLocaleString('ru', { minimumFractionDigits: 4 })
                        : '--'
                }
            </TableCell>
            <TableCell>{Number(deal.commission).toLocaleString('ru', { minimumFractionDigits: 4 })}</TableCell>
            <TableCell>
                <EditDealButton id={deal.id} />
            </TableCell>
        </TableRow>
    )
}