import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithReauth } from "../../api/apiHelpers";
import { CreateInvoicePayload, CreateInvoiceResponse, GetInvoicesPayload, GetInvoicesResponse, Invoice } from "./types";

export const invoiceApi = createApi({
    reducerPath: 'invoices',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['Invoice'],
    endpoints: (builder) => ({
        
        getInvoices: builder.query<GetInvoicesResponse, GetInvoicesPayload>({
            query: (params) => ({
                url: 'invoices',
                params,
            }),
            providesTags: ['Invoice']
        }),

        createInvoice: builder.mutation<CreateInvoiceResponse, CreateInvoicePayload>({
            query: (payload) => ({
                url: 'invoice',
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['Invoice']
        }),

        getInvoice: builder.query<Invoice, string>({
            query: (id) => ({
                url: `invoice/${id}`
            })
        }),

        downloadInvoice: builder.mutation<File, string>({
            query: (id) => ({
                url: `Invoice/${id}/excel-reconciliation`,
                responseHandler: async (response) => {
                    if (response.ok) {
                        const blob = await response.blob();
                        const file = new File([blob], 'invoice.xlsx', { type: 'octet/stream' });
                        return file;
                    }
                },
            }),
        }),

    }),
});

export const useGetInvoices = invoiceApi.endpoints.getInvoices.useQuery;
export const useGetInvoice = invoiceApi.endpoints.getInvoice.useQuery;

export const useCreateInvoice = invoiceApi.endpoints.createInvoice.useMutation;
export const useDownloadInvoice = invoiceApi.endpoints.downloadInvoice.useMutation;