import { Paper, Skeleton, TableContainer, TablePagination } from "@mui/material";
import { useGetInvoices } from "./invoiceApi.slice"
import { InvoicesTable } from "./InvoicesTable";
import { RequestError } from "../../shared/ui/RequestError";
import { InvoiceCreateForm } from "./InvoiceCreateForm";
import { usePagination } from "../../shared/pagination/usePagination";
import { useState } from "react";
import { ClientCodeInput } from "../client/ui/ClientInput";

const POLLING_INTERVEL_MS = 15 * 60 * 1000;

export function InvoicesWidget() {
    const pagination = usePagination();
    const [clientCode, setClientCode] = useState<string>();
    const { currentData, isFetching, error } = useGetInvoices({
        clientCode,
        skip: pagination.currentPageNumber * pagination.rowsPerPage,
        count: pagination.rowsPerPage,
    }, {
        pollingInterval: POLLING_INTERVEL_MS,
    });

    return (
        <div>
            <h2
                style={{
                    'textAlign': 'start'
                }}
            >
                Инвойсы
            </h2>

            <InvoiceCreateForm />

            <ClientCodeInput
                    className="Input"
                    id="clientCode"
                    name="clientCode"
                    placeholder="Клиент"
                    label="Сортировать по клиенту"
                    value={clientCode}
                    onChange={(value: unknown) => setClientCode((value as string) ?? undefined)}
                    style={{
                        margin: '10px 0',
                        paddingTop: '13px',
                        width: '250px'
                    }}
                />

            <TableContainer
                component={Paper}
                sx={{
                    width: 'fit-content'
                }}
            >
                {
                    isFetching
                        ? <Skeleton variant="rectangular" height={300} />
                        : error
                            ? (<RequestError error={error} />)
                            : currentData
                                ? <InvoicesTable invoices={currentData} />
                                : null
                }
                <TablePagination
                    count={-1}
                    page={pagination.currentPageNumber}
                    rowsPerPage={pagination.rowsPerPage}
                    onPageChange={(e, page) => {
                        if (currentData!.length < pagination.rowsPerPage && page > pagination.currentPageNumber) {
                            return;
                        }
                        pagination.changePageNumber(page);
                    }}
                    rowsPerPageOptions={[10, 20, 30]}
                    onRowsPerPageChange={(e) => { pagination.changeRowsPerPage(e.target?.value as unknown as number) }}

                    style={{
                        'marginLeft': 'auto'
                    }}
                />
            </TableContainer>
        </div>
    )
}

