
export function getStartPreviousMonthDateString() {
    const now = new Date();

    now.setDate(1);
    now.setMonth(now.getMonth() - 1);
    if (now.getMonth() === 11) {
        now.setFullYear(now.getFullYear() - 1);
    }

    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    now.setMilliseconds(0);

    return now.toJSON();
}

export function getEndPreviousMonthDateString() {
    const now = new Date();

    now.setDate(0);
    if (now.getMonth() === 11) {
        now.setFullYear(now.getFullYear() - 1);
    }

    now.setHours(23);
    now.setMinutes(59);
    now.setSeconds(59);
    now.setMilliseconds(999);

    return now.toJSON();
}