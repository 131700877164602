import { Chip } from "@mui/material";
import { InvoiceStatus } from "./types";

const invoiceStatusUI = {
    'Unknown': { label: 'Неизвестно', color: 'default' },
    'Created': { label: 'Создан', color: 'success', variant: 'outlined' },
    'Ready': { label: 'Готов', color: 'success' },
    'Confirmed': { label: 'Подтвержден', color: 'success' },
    'Sent': { label: 'Отправлен', color: 'info' },
    'Rejected': { label: 'Отклонен', color: 'error' },
} as Record<InvoiceStatus, { label: string, color: 'default' | 'success' | 'info' | 'error', variant?: 'outlined' | 'filled' }>;

export function InvoiceStatusUI({ status }: { status: InvoiceStatus }) {
    return (
        <Chip
            {...invoiceStatusUI[status]}
        />
    )
}