import { Button, Icon, IconButton, TextField, Tooltip } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

import * as Yup from 'yup';
import { SwapCommission, SwapCommissionItem } from "../types";
import { NumericFormat } from "react-number-format";
import { useEffect, useState } from "react";
import './swapCommissions.css';
import { useFormik } from "formik";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';


const validationSchema = Yup.object({
    rate: Yup.string()
        .test(
            'is-number',
            'Введите число',
            (value) => {
                let schema = Yup.number();
                return schema.isValid(value);
            })
        .test(
            'is-positive',
            'Ставка должна быть больше 0',
            (value) => {
                let schema = Yup.number().positive('Число должно быть 0.');
                return schema.isValid(value);
            }
        )
        .required('Введите число.'),
    lowerBound: Yup.string()
        .test(
            'is-valid-lower-bound',
            'Нижняя граница должна быть положительным целым числом',
            (value) => {
                const number = Number(value);
                return Number.isInteger(number) && number > 0;
            }
        )
        .required('Введите число.'),
    upperBound: Yup.string()
        .test(
            'is-valid-upper-bound',
            'Верхняя граница должна быть положительным целым числом',
            (value) => {
                return value === null || (Number(value) > 0 && Number.isInteger(Number(value)))
            }
        )
        .notRequired(),
});

const defaultSwapItem = { lowerBound: 1, upperBound: null, rate: 0.0007 };

export function SwapForm({
    commission, updateCommission
}: {
    commission?: SwapCommission, updateCommission: (newCommission: SwapCommission) => void,
}) {

    const [swapItems, setSwapItems] = useState(commission ?? [{ ...defaultSwapItem }]);

    useEffect(() => {
        setSwapItems(commission ?? [{ ...defaultSwapItem }]);
    }, [commission]);

    useEffect(() => {
        updateCommission(swapItems);
    }, [swapItems, updateCommission]);

    const onAddItem = () => {
        const newItem = { ...defaultSwapItem };
        setSwapItems([...swapItems, newItem]);
    }

    const onDeleteItem = (order: number) => () => {
        const newItems = swapItems.filter((_, index) => index !== order);
        setSwapItems(newItems);
    }

    const onChange = (order: number) => (newSwapItem: SwapCommissionItem) => {
        const newItems = [...swapItems];
        newItems[order] = newSwapItem;
        setSwapItems(newItems);
    }

    return (
        <form target="_blank" className="swap-commission-form">
            {
                swapItems.map((item, index) => (
                    <SwapItem
                        commissionItem={item}
                        key={index}
                        onChange={onChange(index)}
                        onDelete={onDeleteItem(index)}
                    />
                ))
            }

            <Button
                variant="outlined"
                color="success"
                className="add-swap-item-button"
                sx={{
                    gridColumn: -1,
                    width: '100px'
                }}
                onClick={onAddItem}
            >
                <AddIcon />
            </Button>
        </form>
    )
}

function SwapItem({
    commissionItem, onChange, onDelete
}: {
    commissionItem: SwapCommissionItem,
    onChange: (newItem: SwapCommissionItem) => void,
    onDelete: () => void,
}) {
    const formik = useFormik({
        initialValues: commissionItem,
        validationSchema,
        onSubmit: () => console.log('swap')
    });

    useEffect(() => {
        onChange({
            lowerBound: Number(formik.values.lowerBound),
            upperBound: formik.values.upperBound === null ? formik.values.upperBound : Number(formik.values.upperBound),
            rate: Number(formik.values.rate),
        });
    }, [formik.values.lowerBound, formik.values.upperBound, formik.values.rate]);

    return (
        <div>
            <NumericFormat
                value={formik.values.lowerBound}
                onValueChange={(values) => {
                    const newLowerBound = values.value === '' ? values.value : Number(values.value);
                    formik.handleChange({ target: { value: newLowerBound, id: 'lowerBound' } })
                }}
                isAllowed={(values) => values.value === '' || (Number(values.value) > 0 && Number.isInteger(Number(values.value)))}

                allowNegative={false}
                thousandSeparator

                id="lowerBound"
                name="lowerBound"
                placeholder="Нижняя граница"
                label="Lower"
                required
                margin="normal"
                variant="outlined"
                className="swap-item-bound"
                size="small"
                onBlur={formik.handleBlur}
                error={!!formik.touched.lowerBound && !!formik.errors.lowerBound}

                customInput={TextField}
            />
            <NumericFormat
                value={formik.values.upperBound}
                onValueChange={(values) => {
                    formik.handleChange({ target: { value: values.value === '' ? null : Number(values.value), id: 'upperBound' } });
                }}

                allowNegative={false}
                isAllowed={(values) => values.value === '' || (Number(values.value) > 0 && Number.isInteger(Number(values.value)))}
                thousandSeparator

                id="upperBound"
                name="upperBound"
                placeholder="Верхняя граница"
                label="Upper"
                margin="normal"
                variant="outlined"
                size="small"
                className="swap-item-bound"
                onBlur={formik.handleBlur}
                error={Boolean(formik.touched.upperBound) &&
                    (Boolean(formik.errors.upperBound) || (formik.values.upperBound !== null && formik.values.upperBound <= formik.values.lowerBound))
                }

                customInput={TextField}
            />
            <NumericFormat
                value={formik.values.rate}
                onValueChange={(values) => {
                    formik.handleChange({ target: { value: values.value, id: 'rate' } });
                }}

                allowNegative={false}
                allowedDecimalSeparators={[',']}
                thousandSeparator

                id="rate"
                name="rate"
                placeholder="Ставка"
                label="Ставка"
                required
                margin="normal"
                variant="outlined"
                className="swap-item-rate"
                size="small"
                onBlur={formik.handleBlur}
                error={!!formik.touched.rate && !!formik.errors.rate}

                customInput={TextField}
            />
            <IconButton
                color="error"
                size="small"
                onClick={onDelete}
            >
                <CloseIcon />
            </IconButton>
            {
                (formik.touched.lowerBound && formik.errors.lowerBound)
                    || (formik.touched.upperBound && formik.errors.upperBound)
                    || (formik.touched.rate && formik.errors.rate)
                    ?
                    (<Tooltip
                        title={formik.errors.lowerBound ?? formik.errors.upperBound ?? formik.errors.rate}
                        placement="top"
                        arrow
                    >
                        <Icon color="error">
                            <ErrorOutlineIcon />
                        </Icon>
                    </Tooltip>)
                    : null
            }
        </div>
    )
}