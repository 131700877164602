import { useFormik } from "formik";
import { ClientCodeInput } from "../client/ui/ClientInput";
import { FormLabel } from "@mui/material";
import { DateInput } from "../deals/ui/DealList/DateInput";
import { getEndPreviousMonthDateString, getStartPreviousMonthDateString } from "./utils";
import { useCreateInvoice } from "./invoiceApi.slice";
import { LoadingButton } from "@mui/lab";
import * as Yup from 'yup';

const initialValues = {
    clientCode: '',
    from: getStartPreviousMonthDateString(),
    to: getEndPreviousMonthDateString(),
}

const validationSchema = Yup.object({
    clientCode: Yup.string().required(),
    from: Yup.string().required(),
    to: Yup.string().required(),
})

export function InvoiceCreateForm() {
    const [createInvoice, metadata] = useCreateInvoice();

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            console.log(values);
            if (values.clientCode) {
                createInvoice(values);
            }
        }
    })


    return (
        <form 
            onSubmit={formik.handleSubmit}
            style={{
                marginBottom: '30px'
            }}
        >
            <div style={{ display: 'flex', alignItems: 'start', gap: '20px' }}>
                <ClientCodeInput
                    className="Input"
                    id="clientCode"
                    name="clientCode"
                    placeholder="Клиент"
                    label="Клиент"
                    required={true}
                    value={formik.values.clientCode}
                    onChange={(value: unknown) => {
                        formik.values.clientCode = value as string;
                    }}
                    onBlur={formik.handleBlur}
                    error={!!formik.touched.clientCode && !!formik.errors.clientCode}
                    helperText={formik.touched.clientCode ? formik.errors.clientCode : null}
                    style={{
                        margin: '10px 0',
                        paddingTop: '13px'
                    }}
                />

                <div>
                    <FormLabel sx={{ margin: '0 15px' }}>Начало</FormLabel>
                    <DateInput
                        value={formik.values.from ? new Date(formik.values.from) : new Date()}
                        setValue={(newDate) => formik.handleChange({ target: { value: newDate.toJSON(), id: 'from' } })}
                        isTime={false}
                    />
                </div>
                <div>
                    <FormLabel sx={{ margin: '0 15px' }}>Конец</FormLabel>
                    <DateInput
                        value={formik.values.to ? new Date(formik.values.to) : new Date()}
                        setValue={(newDate) => formik.handleChange({ target: { value: newDate.toJSON(), id: 'to' } })}
                        isTime={false}
                    />
                </div>

                <LoadingButton
                    variant="contained"
                    type="submit"
                    sx={{ height: '52px', marginTop: '23px' }}
                    loading={metadata.isLoading}
                >
                    Создать инвойс
                </LoadingButton>
            </div>
        </form>
    )
}