import { LoadingButton } from "@mui/lab";
import { useEffect, useRef, useState } from "react";
import { useDownloadInvoice } from "./invoiceApi.slice";
import DownloadIcon from '@mui/icons-material/Download';
import { Invoice } from "./types";

export function InvoiceDownloadButton({ invoice }: { invoice: Invoice }) {
    const [download, metadata] = useDownloadInvoice();
    const [url, setUrl] = useState('');
    const [filename, setFilename] = useState('');
    const aRef = useRef<HTMLAnchorElement>(null);

    useEffect(() => {
        if (url && filename && aRef.current) {
            aRef.current?.click();
        }
    }, [url, filename]);

    return (
        <>
            <LoadingButton
                loading={metadata.isLoading}

                onClick={async () => {
                    const response = await download(invoice.id);
                    if ('data' in response) {
                        const newUrl = window.URL.createObjectURL(response.data);
                        const fileName = `invoice_${invoice.clientCode}_start=${invoice.from}_end=${invoice.to}.xlsx`;
                        setUrl(newUrl);
                        setFilename(fileName);
                        window.URL.revokeObjectURL(url);
                    }
                }}
            >
                <DownloadIcon />
            </LoadingButton>

            <a
                ref={aRef}
                style={{
                    display: 'none'
                }}
                href={url}
                download={filename}
            />
        </>
    )
}