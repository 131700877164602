import { Button, Skeleton } from "@mui/material";
import { CommissionForm } from "./CommissionForm"
import { useGetCommission } from "./commissions.slice";
import { CommissionsType, commissionsTypes } from "./types"
import './commission.css';
import { useMemo, useState } from "react";


export function ClientCommissions({
    clientCode, commissionType
}: {
    clientCode: string, commissionType?: CommissionsType
}) {
    const commissionsToDisplay = commissionType ? [commissionType] : commissionsTypes;

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
            {
                commissionsToDisplay.map((commission, index) =>
                (<CommissionClientCard
                    clientCode={clientCode}
                    commissionType={commission}
                    key={commission}
                />)
                )
            }
        </div>
    )
}

export function CommissionClientCard({
    clientCode, commissionType
}: {
    clientCode: string, commissionType: CommissionsType
}) {
    const { currentData, isFetching, error } = useGetCommission({ code: clientCode, commissionType });

    const [showCommissionForm, setShowCommissionForm] = useState(false);

    const result = {
        'loading': {
            ui: () => <Skeleton variant="rectangular" width={500} height={60} sx={{ flexGrow: 2 }} />,
        },
        'commissionExists': {
            ui: () => <CommissionForm
                commissionType={commissionType}
                clientCode={clientCode}
                commission={currentData ? { value: currentData } : undefined}
                closeForm={() => setShowCommissionForm(false)}
            />
        },
        'noCommission': {
            ui: () => (
                showCommissionForm
                    ? <CommissionForm commissionType={commissionType} clientCode={clientCode} commission={undefined} closeForm={() => setShowCommissionForm(false)} />
                    : <Button onClick={() => setShowCommissionForm(true)}>Добавить комиссию</Button>
            )
        },
        'error': {
            ui: () => (<p>Error {error && 'message' in error ? error.message : ''}</p>)
        }
    };

    const handleResult = useMemo(() => {
        if (isFetching) {
            return 'loading';
        }

        if (error && 'status' in error && error.status === 404) {
            return 'noCommission';
        }

        if (currentData) {
            return 'commissionExists';
        }

        return 'error';
    }, [isFetching, error, currentData]);

    return (
        <div className="commission-client-card">
            <h4>{commissionType}</h4>
            {
                result[handleResult].ui()
            }
        </div>
    )
}