import { createBrowserRouter } from "react-router-dom";

import { DealFormWidget } from "../widgets/DealFormWidget/DealFormWidget";
import { ClientWidget } from "../widgets/ClientWidget/ClientWidget";
import { ErrorPage } from "../pages/ErrorPage";
import LoginPage from "../features/authentication/ui/LoginPage";
import { AddClientWidget } from "../widgets/AddClientWidget/AddClientWidget";
import { ClientCardWidget } from "../widgets/ClientCardWidget/ClientCardWidget";
import { getClientIdLoader } from "../features/client/ui/EditClientForm";
import { IndexWidget } from "../widgets/IndexWidget/IndexWidget";
import { AuthGateway } from "./withAuthorized/AuthGateway";
import { getDealIDLoader } from "../features/deals/ui/EditDealButton";
import { EditDealWidget } from "../widgets/EditDealWidget/EditDealWidget";
import { SettingsWidget } from "../widgets/Settings/SettingsWidget";
import { DealListContainer } from "../features/deals/ui/DealList/DealList";
import { MainApp } from "../features/layout/MainAppLayout";
import { CommissionWidget } from "../features/commissions/CommissionWidget";
import { InvoicesWidget } from "../features/invoices/InvoicesWidget";


export const appRouter = createBrowserRouter([
    {
        path: '/login',
        element: <LoginPage />,
        errorElement: <ErrorPage />
    },
    {
        path: '/',
        element: <AuthGateway>
            <MainApp />
        </AuthGateway>,
        errorElement: <ErrorPage />,
        children: [
            {
                index: true,
                element: <IndexWidget />,
            },
            {
                path: 'dealform',
                element: <DealFormWidget />,
            },
            {
                path: 'clients',
                element: <ClientWidget />,
            },
            {
                path: 'clients/:clientId',
                element: <ClientCardWidget />,
                loader: getClientIdLoader,
            },
            {
                path: 'deals',
                element: <DealListContainer />,
            },
            {
                path: 'deals/:dealId',
                element: <EditDealWidget />,
                loader: getDealIDLoader,
            },
            {
                path: 'addNewClient',
                element: <AddClientWidget />,
            },
            {
                path: 'settings',
                element: <SettingsWidget />
            },
            {
                path: 'commissions',
                element: <CommissionWidget />
            },
            {
                path: 'invoices',
                element: <InvoicesWidget />
            },
            // {
            //     path: 'invoices/:invoiceId',
            //     element: <InvoiceCardWidget />,
            //     loader: getInvoiceIdLoader
            // }
            {
                path: '*',
                element: <p>Page Not Found</p>
            }
        ]
    }
])