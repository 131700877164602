import { createApi } from "@reduxjs/toolkit/dist/query/react";

import { baseQueryWithReauth } from "../../api/apiHelpers";
import { mapDealErrorToRuMessage } from "./dealHelpers";
import { Deal, FormDeal, Instrument } from "./deal.dto";
import { Pagination } from "../../shared/pagination/pagination.dto";
import { ResponseError } from "../../api/api.dto";
import { DealFilterParams } from "./ui/DealList/DealFilter";
import { ErrorApi } from "../../shared/types";


export const dealAPI = createApi({
    reducerPath: 'dealAPI',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['Deal'],
    endpoints: (builder) => ({

        fetchDeal: builder.query<Deal, string>({
            query: (id: string) => ({
                url: `deal/${id}`,
                method: 'GET'
            })
        }),

        fetchDeals: builder.query<Deal[], { pagination: Pagination, filterParams?: Partial<DealFilterParams> }>({
            query: (params) => ({
                url: params.filterParams?.ClientCode ? 'deals/by-client' : 'deals',
                method: 'GET',
                params: {
                    ...params.pagination,
                    ...params.filterParams,
                }
            }),
            providesTags: () => ['Deal'],
            transformErrorResponse: (error) => error.data as ErrorApi,
            keepUnusedDataFor: 10,
        }),

        fetchInstruments: builder.query<Instrument[], unknown>({
            query: () => ({
                url: 'dictionaries/InstrumentInfo',
                method: 'GET'
            })
        }),

        downloadDeals: builder.mutation<File, Partial<DealFilterParams>>({
            query: (params) => ({
                url: 'deals/excel',
                params: params,
                responseHandler: async (response) => {
                    if (response.ok) {
                        const blob = await response.blob();
                        const file = new File([blob], 'deals.xlsx', { type: 'octet/stream' });
                        return file;
                    }
                },
            }),
        }),

        addDeal: builder.mutation<unknown, FormDeal>({
            query: (deal: FormDeal) => ({
                url: 'deal',
                method: 'POST',
                body: deal
            }),
            transformErrorResponse: (
                response,
                meta,
                arg
            ) => mapDealErrorToRuMessage(response.data as ResponseError),
            invalidatesTags: () => ['Deal']
        }),

        editDeal: builder.mutation<unknown, Deal>({
            query: (deal: Deal) => ({
                url: `deal/${deal.id}`,
                method: 'PATCH',
                body: deal
            }),
            transformErrorResponse: (
                response,
                meta,
                arg
            ) => mapDealErrorToRuMessage(response.data as ResponseError),
            invalidatesTags: () => ['Deal']
        }),

        deleteDeal: builder.mutation<unknown, string>({
            query: (id: string) => ({
                url: `deal/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: () => ['Deal']
        })
    })
})


export const useFetchDeal = dealAPI.endpoints.fetchDeal.useQuery;
export const useFetchDeals = dealAPI.endpoints.fetchDeals.useQuery;
export const useFetchInstruments = dealAPI.endpoints.fetchInstruments.useQuery;

export const useDownloadDeals = dealAPI.endpoints.downloadDeals.useMutation;

export const useAddDeal = dealAPI.endpoints.addDeal.useMutation;
export const useEditDeal = dealAPI.endpoints.editDeal.useMutation;
export const useDeleteDeal = dealAPI.endpoints.deleteDeal.useMutation;